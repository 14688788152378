import * as React from 'react';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { useTranslation } from 'react-i18next';
export const ConstDropDownCell = ({ constdata = [], ...props }) => {
    const { t } = useTranslation()
    let name = '';
    const handleChange = e => {
        if (props.onChange) {
            props.onChange({
                dataIndex: 0,
                dataItem: props.dataItem,
                field: props.field,
                syntheticEvent: e.syntheticEvent,
                value: e.target.value.value
            });
        }
    };

    const {
        dataItem
    } = props;
    const field = props.field || '';
    const dataValue = dataItem[field] === null ? '' : dataItem[field];
    for (let index = 0; index < constdata.length; index++) {
        const element = constdata[index];
        constdata[index].text = t(element.name);
        if (((element.value + '').toUpperCase()) === (dataValue + '').toUpperCase()) {
            name = element.name;
            break;
        }
    }
    return <td>
        {dataItem.inEdit ? <DropDownList style={{
            width: "100%"
        }}
            onChange={handleChange}
            value={constdata.find(c => (c.value + '').toUpperCase() === (dataValue + '').toUpperCase())}
            data={constdata}
            textField="text"
        /> : (dataValue) + (name ? (' - ' + t(name)) : '')}
    </td>;
};