export const requestData = {
    orders: [],
    perPage: 0,
    page: 1,
    filters: [],
};

export const pagerSettings = {
    buttonCount: 5,
    info: true,
    type: "numeric",
    pageSizes: [10, 20, 50, 100, 500],
    previousNext: true,
    refresh: true,
};

export const OPERATORS_MAP = {
    eq: "=",
    neq: "<>",
    startswith: "LIKE",
    lt: "<",
    lte: "<=",
    gt: ">",
    gte: ">=",
    isnull: "NULL",
    isnotnull: "NOTNULL",
    in: "IN",
    notin: "NOT IN",
    endswith: "LIKE",
    contains: "LIKE",
};
