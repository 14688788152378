import { MagnifyingGlassCircleIcon, XMarkIcon } from '@heroicons/react/24/outline'
import React, { Fragment, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Dialog, Transition } from '@headlessui/react'
import { MultiSelect } from '@progress/kendo-react-dropdowns'
import { FibaDatePicker, FibaDropdown, FibaInput, FibaInputNumber, FibaMultiSelect } from '../../../input'
import { Field, Form, FormElement } from '@progress/kendo-react-form'
import { useLocalization } from '@progress/kendo-react-intl'
import { CustomFilterOperators } from '../../column/service/FilterOperatorsCustom'
import { OperatorChar } from '../../column/service/operators'
import { dateFormat } from '../../../service/functions'

export const FibaFilter = ({
  filterfields = [],
  requestData,
  setRequestData,
}) => {
  const { t } = useTranslation();
  const localization = useLocalization();
  const [open, setOpen] = React.useState(false)
  const [searchValues, setSearchValues] = React.useState([])
  const [condData, setCondData] = React.useState({});
  const [searchInitial, setSearchInitial] = React.useState({});
  const [showSearchBox, setShowSearchBox] = React.useState(true);
  const changeRequestData = (data) => {
    if (requestData && requestData.filters) {
      const tmpvalue = [];
      for (let index = 0; index < requestData.filters.length; index++) {
        const element = requestData.filters[index];
        let isinclude = false;
        for (let ind2 = 0; ind2 < data.length; ind2++) {
          const elmnt = data[ind2];
          if (elmnt.field === element.field) {
            isinclude = true;
            break;
          }
        }
        if (isinclude) {
          tmpvalue.push(element);
        }
      }
      setRequestData({ ...requestData, filters: tmpvalue, page: 1 });
    }
  }

  const changeSelectedValue = (event) => {
    setSearchValues(event.value);
    changeRequestData(event.value);
  }

  const searchFieldopen = (event) => {
    setOpen(true)
  }

  const searchData = (render) => {
    const tmpsearch = [];
    setOpen(false)
    for (let index = 0; index < filterfields.length; index++) {
      const element = filterfields[index];
      let value = render.valueGetter(`${element.field}-value`);
      if ((value || value === 0)
        && render.valueGetter(`${element.field}-operator`)) {
        if (element.type === 'date') {
          value = dateFormat(value);
        }
        switch (render.valueGetter(`${element.field}-operator`)) {
          case OperatorChar.contains:
            value = `%${value}%`;
            break;
          case OperatorChar.in:
          case OperatorChar.notin:
            if (typeof value == 'object') {
              let values = [];
              value.forEach(unitelmnt => {
                values.push(unitelmnt.value);
              });
              value = values;
            }
            break;

          default:
            break;
        }
        tmpsearch.push({
          field: element.field,
          cond: render.valueGetter(`${element.field}-operator`),
          value: value
        })
      }
    }
    setRequestData({ ...requestData, filters: tmpsearch, page: 1 });
  }

  const clearSearchData = () => {
    setOpen(false)
    setRequestData({ ...requestData, filters: [], page: 1 });
  }

  useEffect(() => {
    if (requestData && requestData.filters) {
      const tmpvalue = [];
      const tmpinitialvalue = {};
      for (let index = 0; index < requestData.filters.length; index++) {
        const element = requestData.filters[index];
        let searchvalue = element.value;
        let searchcond = element.cond;
        tmpinitialvalue[`${element.field}-operator`] = searchcond;
        if (searchcond === 'LIKE') {
          searchvalue = searchvalue.replaceAll('%', '')
        }
        tmpinitialvalue[`${element.field}-value`] = searchvalue;
        switch (searchcond.toUpperCase()) {
          case OperatorChar.in:
          case OperatorChar.notin:
            if (typeof searchvalue == 'object') {
              let value = '', tmpdata = [];
              searchvalue.forEach(unitelmnt => {
                tmpdata.push({ value: unitelmnt, name: unitelmnt });
                value = `${(value ? value + ';' : value)}${unitelmnt}`
              });
              searchvalue = value;
              tmpinitialvalue[`${element.field}-value`] = tmpdata
            }
            break;
          default:

            break;
        }

        tmpvalue.push({
          textField: `${t(element.field)} ${searchcond} '${searchvalue}'`,
          field: element.field
        });
      }
      for (let index = 0; index < filterfields.length; index++) {
        const element = filterfields[index];
        if (!tmpinitialvalue[`${element.field}-value`] && (tmpinitialvalue[`${element.field}-value`] !== 0)) {
          tmpinitialvalue[`${element.field}-operator`] = OperatorChar.eq;
        }
      }
      setSearchInitial(tmpinitialvalue)
      setSearchValues(tmpvalue)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestData])

  React.useEffect(() => {
    let tmpdata = {};
    for (const key in CustomFilterOperators) {
      if (Object.hasOwnProperty.call(CustomFilterOperators, key)) {
        const element = CustomFilterOperators[key];
        element.forEach(item => {
          if (!tmpdata[key]) {
            tmpdata[key] = [];
          }

          tmpdata[key].push({
            text: localization.toLanguageString(item.text, item.operator),
            operator: item.operator
          });
        });
      }
    }
    setCondData(tmpdata);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getFilterInput = ({ type, field, dictCode, constData }) => {
    let input = FibaInput;
    const customRef = {};
    switch (type) {
      case 'date':
        input = FibaDatePicker
        break;
      case 'numeric':
        input = FibaInputNumber
        break;
      case 'select':
        input = FibaDropdown
        if (dictCode) {
          customRef.dictCode = dictCode;
        }
        if (constData) {
          customRef.data = constData;
        }
        break;
      case 'multiselect':
        input = FibaMultiSelect
        if (dictCode) {
          customRef.dictCode = dictCode;
          customRef.textField = "name";
          customRef.dataItemKey = "value";
        }
        break;
      default:
        break;
    }
    return <Field
      name={`${field}-value`}
      component={input}
      {...customRef}
    />;
  }

  const FibaFilterForm = ({ element }) => {
    return <div className='flex space-x-2'>
      <div className='pt-2' style={{ width: '150px' }}>
        {element.title}
      </div>
      <Field
        data={condData[element.type] ?? []}
        textField={"text"}
        valueField={'operator'}
        name={`${element.field}-operator`}
        component={FibaDropdown}
        style={{ width: '150px' }}
        className={'z-[20000]'}
      />
      <div style={{ width: '300px' }}>
        {getFilterInput(element)}
      </div>

    </div>
  }

  React.useEffect(() => {
    setShowSearchBox(false);
  }, [searchInitial])

  React.useEffect(() => {
    if (!showSearchBox) {
      setShowSearchBox(true);
    }
  }, [showSearchBox])

  return (
    <div>
      <div className='flex w-1/2 min-w-[100%] max-w-[80vw]'>
        <MultiSelect
          value={searchValues}
          onChange={changeSelectedValue}
          rounded={'full'}
          fillMode={'solid'}
          onOpen={searchFieldopen}
          placeholder={`${t('search')}...`}
          textField={'textField'}
          opened={false}
          style={{
            width: '100%',
            maxHeight: '70px',
            overflow: 'auto',
            minWidth: '200px'
          }}
        />
        {/* <FibaButton
          onClick={showSearchDialog}
          title={t('refresh')}
          buttonType={'icon'}
          themeColor={'none'}
          icon={<MagnifyingGlassIcon className='h-5 w-5' />}
        >
        </FibaButton> */}
      </div>

      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-[10006]" onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl sm:p-6">
                  <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                    <button
                      type="button"
                      className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      onClick={() => setOpen(false)}
                    >
                      <span className="sr-only">Close</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                  <div className="mt-3 text-center sm:ml-2 sm:mt-0 sm:text-left ">
                    <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900 flex">
                      <MagnifyingGlassCircleIcon className="h-10 w-10 text-primary-600" aria-hidden="true" />
                      <div className='ml-3' style={{ paddingTop: '7px' }}>
                        {t('search')}
                      </div>
                    </Dialog.Title>
                    <hr />
                    <div className="mt-2">
                      {showSearchBox && <Form
                        initialValues={searchInitial}
                        render={(formRenderProps) => (
                          <FormElement>
                            <fieldset className={"k-form-fieldset"}>

                              {filterfields.map((element, index) => {
                                return <FibaFilterForm key={index} element={element} />
                              })}
                            </fieldset>
                            <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                              <button
                                type="button"
                                className="inline-flex w-full justify-center rounded-md bg-primary-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-primary-500 sm:ml-3 sm:w-auto"
                                onClick={() => searchData(formRenderProps)}
                                disabled={!formRenderProps.allowSubmit}
                              >
                                {t('search')}
                              </button>
                              <button
                                type="button"
                                className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                                onClick={() => clearSearchData()}
                              >
                                {t('clear')}
                              </button>

                            </div>
                          </FormElement>
                        )}
                      />}

                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  )
}
