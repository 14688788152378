import { useTableKeyboardNavigation } from '@progress/kendo-react-data-tools';
import { GRID_COL_INDEX_ATTRIBUTE } from '@progress/kendo-react-grid';
import { Link, useLocation } from "react-router-dom";
import React from 'react'

const DetailCell = ({ dataItemKey = 'id', customDetailUrl, detailFuncion, ...props }) => {
    const field = props.field || "";
    const value = props.dataItem[field];
    const navigationAttributes = useTableKeyboardNavigation(props.id);
    const location = useLocation()
    let detailUrl = location.pathname;
    if (customDetailUrl) {
        detailUrl = customDetailUrl;
    }

    const clickDetail = () => {
        if (detailFuncion) {
            detailFuncion(props.dataItem)
        }
    }
    return (
        <td
            style={{ ...props.style, ...{ color: 'blue' } }}
            colSpan={props.colSpan}
            role={"gridcell"}
            aria-colindex={props.ariaColumnIndex}
            aria-selected={props.isSelected}
            className={props.className}
            {...{
                [GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex,
            }}
            {...navigationAttributes}
        >
            {/* <div onClick={()=>{navigate(`${detailUrl}/${props.dataItem[dataItemKey]}`)}}>{value}</div> */}
            <Link to={detailFuncion ? `${window.location.search}` : `${detailUrl}/${props.dataItem[dataItemKey]}${window.location.search}`} onClick={clickDetail}>{value}</Link>
        </td>
    );
}

export default DetailCell
