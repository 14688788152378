import React from 'react'
import { ListScreen } from '../components'
import { GridColumn } from '@progress/kendo-react-grid'
import { useTranslation } from 'react-i18next'

const Module = () => {
    const { t } = useTranslation()
    return (
        <div>
            <ListScreen
                read={'ll000001'}
                delete={'ll000004'}
                selectedFieldName={'domain_name'}
            >
                <GridColumn field='id' title={t('id')}></GridColumn>
                <GridColumn field='domain_name' title={t('domain_name')}></GridColumn>
            </ListScreen>
        </div>
    )
}

export default Module