import { InboxArrowDownIcon, XCircleIcon } from '@heroicons/react/24/outline'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useFibaMsgBox, useLoading } from '../../features'
import { GetCookie, SERVER_HOST } from '../../service'
import './index.css'

const TOKEN_NAME = process.env.REACT_APP_TOKEN_NAME;

export const FibaPhotoInput = ({
    validationMessage,
    visited,
    required,
    label,
    fieldInfo,
    disabled,
    value,
    onChange,
    modified,
    valid,
    touched,
    type = 'GPA',
    width = '100px',
    height = '110px',
    name = 'photo',
    ...others
}) => {
    const [selectedFile, setSelectedFile] = useState()
    const [preview, setPreview] = useState({})
    const { showLoading } = useLoading()
    const [errormsg, setError] = useState()
    const { t } = useTranslation()
    const { confirm } = useFibaMsgBox()

    useEffect(() => {
        if (!selectedFile) {
            setPreview({ [name]: value ? `${SERVER_HOST}/api/v1/image?id=${value}&type=${type}` : null })
            return
        }
        const objectUrl = URL.createObjectURL(selectedFile)
        setPreview({ [name]: objectUrl })
        return () => URL.revokeObjectURL(objectUrl)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedFile, value, type])

    const uploadPhoto = async (data) => {

        const formData = new FormData();
        formData.append("image", data);
        formData.append("type", type);

        showLoading(true)
        setError(null)
        axios.post(SERVER_HOST + "/api/v1/upload/image", formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: 'Bearer ' + GetCookie(TOKEN_NAME),
            },
            transformRequest: formData => formData,
        }).then((res) => {
            if (res.data.response_code === "RC000000") {
                // setId(res.data.response)
                onChange({ value: res.data.response });
                setSelectedFile(data)
            } else {
                setError(t('cannot_upload'))
                console.error(res.data);
            }
        }).catch((err) => {
            setError(t('cannot_upload'))
            console.log(err);
        }).finally(() => {
            showLoading(false);
        })
    }

    const onChangePicture = (e) => {
        if (!e.target.files || e.target.files.length === 0) {
            setSelectedFile(undefined)
            return;
        }
        uploadPhoto(e.target.files[0])
    }

    const remove = async (e) => {
        e.preventDefault()
        const isconfirm = await confirm(t('confirmdelete'));
        if (isconfirm) {
            setSelectedFile(undefined)
            onChange({ value: '' });
        }
    }

    return (
        <div className='flex w-full'>
            <React.Fragment>
                <input
                    className='sr-only'
                    required={required}
                    disabled={disabled}
                    modified={modified ? modified + "" : ""}
                    touched={touched ? touched + "" : ""}
                    valid={valid ? valid + "" : ""}
                    onChange={onChange}
                    {...others}
                    value={value || ''}
                />
                <input
                    className='sr-only'
                    accept="image/*"
                    id={"fiba-file" + name}
                    type={'file'}
                    valid={valid ? 'true' : 'false'}
                    touched={touched ? 'true' : 'false'}
                    modified={modified ? 'true' : 'false'}
                    visited={visited ? 'true' : 'false'}
                    onChange={onChangePicture}
                    name={'file_image'}
                />
                <label
                    className='fiba-file-input k-input k-input-md k-rounded-md k-input-solid'
                    htmlFor={"fiba-file" + name}
                    style={{ width: width, height: height }}
                >
                    {!preview[name] &&
                        <div className='w-full text-center pt-3 text-gray-500'>
                            <InboxArrowDownIcon className='w-12 h-12 mx-auto' />
                            {!errormsg && <div className='mt-3 text-xs'>
                                {t('upload_photo')}
                            </div>}
                            {errormsg && <div className='text-red-400 text-xs'>{errormsg}</div>}
                        </div>
                    }
                    {preview[name] &&
                        <React.Fragment>
                            <div className='fiba-file-input flex justify-center'>
                                <div className='m-auto'>
                                    <img
                                        src={preview[name]} alt="upload"
                                        className='w-full hover:scale-105 transition duration-300 ease-in-out'
                                        loading='lazy'
                                    />
                                </div>
                            </div>
                        </React.Fragment>

                    }

                </label>
                {preview[name] &&
                    <React.Fragment>
                        <div className='relative'>
                            <div className="absolute -top-3 -right-3 cursor-pointer">
                                <XCircleIcon className='w-6 h-6 fill-white' onClick={remove} />
                            </div>
                        </div>
                    </React.Fragment>

                }
            </React.Fragment>
        </div>
    )
}