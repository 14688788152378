import React from 'react'
import { FibaInput, FibaPhotoInput, Form } from '../components'
import { Field } from '@progress/kendo-react-form'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

const OrgLogoDetail = () => {

    const { t } = useTranslation()
    const { id } = useParams()

    return (
        <div className="mx-auto max-w-7xl">
            <Form
                initialValues={{ is_public: 0 }}
                detail={'ll000005'}
                create={'ll000002'}
                update={'ll000003'}
                id={id}
            >
                <div className="grid md:grid-cols-2 sm:grid-cols-1 gap-4">
                    <Field
                        name={'domain_name'}
                        component={FibaInput}
                        label={t('domain_name')}
                        required={true}
                    />
                    <Field
                        name={'logo'}
                        component={FibaPhotoInput}
                        label={t('logo')}
                        required={true}
                    />

                </div>
            </Form>

        </div>
    )
}

export default OrgLogoDetail